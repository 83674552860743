<template>
    <div class="content index-con">
        <div class="con-bnr">
            <div class="bnr-item">
                <div class="bnr-item-txt">学车流程</div>
                <p>
                    <span
                        class="item-bag01"
                        @click="$router.push({path:'/choiceCar',query:{type:1}})"
                    >科目一</span>
                </p>
                <p>
                    <span
                        class="item-bag02"
                        @click="$router.push('/video')"
                    >科目二</span>
                </p>
                <p>
                    <span
                        class="item-bag03"
                        @click="$router.push('/video')"
                    >科目三</span>
                </p>
                <p>
                    <span
                        class="item-bag04"
                        @click="$router.push({path:'/choiceCar',query:{type:4}})"
                    >科目四</span>
                </p>
            </div>
            <el-carousel
                height="380px"
                arrow="nerver"
                :loop="true"
            >
                <el-carousel-item
                    v-for="(item,index) in banners"
                    :key="index"
                >
                    <img
                        :src="item.ImgUrl || require('../../images/wide_seat.png')"
                        alt
                        @click="outerUrl(item)"
                    />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="con-exam">
            <div class="exam-tit">{{carName}}资格证理论考试</div>
            <div class="exam-con">
                <div class="exam-con-bg">
                    <div class="exam-con01">
                        <div class="exam-bg01">
                            <div class="exam-con-tit">科目一交规</div>
                            <div
                                class="exam-con-test test-txt01"
                                v-if="category4.length > 0"
                            >
                                <template v-for="(item,index) in category1">
                                    <p
                                        :key="index"
                                        v-if="item.Id === -2"
                                        class="test-lin01"
                                        @click="$router.push({path:'/modelTest',query:{ type:1,carId:car.selectId }})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.Id === -1"
                                        class="test-lin01"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:1}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.HasChildProblem"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:1}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.HasChildCategory"
                                        @click="$router.push({path:`/skillTest/${item.Id}`,query:{name:item.Name,type:1}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else
                                        @click="noCategory"
                                    >{{item.Name}}</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exam-con-bg">
                    <div class="exam-con02">
                        <div class="exam-bg02">
                            <div class="exam-con-tit">科目四交规</div>
                            <div
                                class="exam-con-test test-txt02"
                                v-if="category4.length > 0"
                            >
                                <template v-for="(item,index) in category4">
                                    <p
                                        :key="index"
                                        v-if="item.Id === -2"
                                        class="test-lin02"
                                        @click="$router.push({path:'/modelTest',query:{ type:4,carId:car.selectId }})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.Id === -1"
                                        class="test-lin02"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:4}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.HasChildProblem"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:4}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else-if="item.HasChildCategory"
                                        @click="$router.push({path:`/skillTest/${item.Id}`,query:{name:item.Name,type:4}})"
                                    >{{item.Name}}</p>
                                    <p
                                        :key="index"
                                        v-else
                                        @click="noCategory"
                                    >{{item.Name}}</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="con-rmd">
            <div class="rmd-tit">
                推荐文章
                <span @click="$router.push('/article')">更多 ></span>
            </div>
            <div v-if="list.length > 0">
                <div
                    class="rmd-article"
                    style="width: 1200px;"
                >
                    <div
                        class="article-con"
                        v-for="(item, index) in list"
                        :key="index"
                        @click="$router.push(`/articleDetails/${item.Id}`)"
                    >
                        <div class="article-con-img">
                            <img :src="item.ImgUrl || require('../../images/seat.png')" />
                        </div>
                        <div class="article-con-txt">
                            <div class="article-ctit">{{item.Title}}</div>
                            <div class="article-ctxt">{{item.SubTitle}}</div>
                        </div>
                    </div>
                </div>
                <!-- 分页 -->
                <div class="artice_page zhong center">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="totalCount"
                        :page-size="model.pageSize"
                        :current-page.sync="model.page"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
            <template v-else>
                <no-content></no-content>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import 'home.scss';
.el-carousel {
    .el-carousel__item {
        text-align: center;
        img {
            height: 100%;
        }
    }
}
</style>
<script>
import { mapState } from 'vuex';

export default {
    name: '',
    data() {
        return {
            loading: null,
            model: {
                page: 1,
                pageSize: 4,
                recommend: 1
            },
            totalCount: 0,
            list: [],
            banners: [],
            category1: [],
            category4: []
        };
    },
    computed: {
        ...mapState(['car']),
        carName() {
            if (!this.car.car) {
                return '';
            }
            const currentCar = this.car.car.find(a => a.Id === this.car.selectId);
            return currentCar ? currentCar.Name : '';
        }
    },
    watch: {
        'car.selectId': {
            handler() {
                this.getData();
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
            this.loading = this.$loading({
                lock: true
            });
            const promiseArr = [this.$api.getHomeBanner(), this.$api.getArticleList(this.model), this.$api.getHomeCategory({ carId: this.car.selectId })];
            Promise.all(promiseArr).then(resArr => {
                this.loading.close();
                for (let i = 0; i < resArr.length; i++) {
                    const res = resArr[i];
                    switch (i) {
                        case 0:
                            if (res.data.length === 0) {
                                this.banners.push({
                                    ImgUrl: require('../../images/wide_seat.png')
                                });
                            } else {
                                this.banners = res.data;
                            }
                            break;
                        case 1:
                            this.list = res.data;
                            this.totalCount = res.TotalCount;
                            break;
                        case 2:
                            const lastArr = [{ Id: -1, Name: '我的错题' }, { Id: -2, Name: '模拟考试' }];
                            this.category1 = [...res.km1, ...lastArr];
                            this.category4 = [...res.km4, ...lastArr];
                            break;
                    }
                }
            }).catch(err => {
                this.loading.close();
                console.error(err);
            });
        },
        /**
         * 分页
         */
        handlePageChange() {
            this.loading = this.$loading({
                lock: true
            });
            this.$api.getArticleList(this.model).then(res => {
                this.loading.close();
                this.list = res.data;
                this.totalCount = res.TotalCount;
            }).catch(err => {
                this.loading.close();
                console.error(err);
            });
        },
        noCategory() {
            this.$message({
                type: 'info',
                message: '该分类为空',
                showClose: true
            });
        },
        // 跳转
        outerUrl(item) {
            if (item) {
                switch (item.UrlType) {
                    case 1:
                        if (item.ArticleId > 0) {
                            this.$router.push(`articleDetails/${item.ArticleId}`);
                        }
                        break;
                    case 2:
                        if (item.Url) {
                            window.open(item.Url, '_blank');
                        }
                        break;
                }
            }
        }
    }
};
</script>
